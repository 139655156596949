import MaintenanceSVG from './gears.svg';

export const MaintenancePage: React.FunctionComponent = () => {
  return (
    <div className='text-center mt-4 empty-state-message'>
      <img src={MaintenanceSVG} alt='404 Not Found!' />
      <h5>We'll Be Back Soon!</h5>
      <p className='text-muted'>
        The site is currently in <strong>Maintenance Mode</strong> while we perform some updates.<br />
        <i>...but, don't worry, <a href='https://xkcd.com/1994/'>it shouldn't take long</a>.</i>
      </p>
      <p className='text-muted'>Please check back in a little while!</p>
    </div>
  )
};
