import { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Callout } from "../components/Callout";
import { EventDate } from "../components/EventDate";
import { EventPost } from "../components/EventPost";
import { GoogleIcon } from "../components/GoogleIcon";
import { UserAvatar } from "../components/UserAvatar";
import { Event } from "../models/Event";
import { Post } from "../models/Post";
import { Role } from "../models/User";
import { NotFoundPage } from "./errors/NotFound";

type Props = RouteComponentProps<{ id: string; }>;

export const EventPostList: React.FunctionComponent<Props> = ({ match: { params: { id } } }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [event, setEvent] = useState<Event>();
  const [posts, setPosts] = useState<Post[]>();

  useEffect(() => {
    api.getEventById(id)
      .then(e => {
        setEvent(e);
        api.getEventPosts(id).then(p => setPosts(p)).catch(console.log);
        setIsLoaded(true);
      })
      .catch(err => {
        console.log(err);
        setIsLoaded(true);
      });
  }, [id]);

  if (!isLoaded) {
    return (
      <div className="d-flex justify-content-center mt-4">
        <div className="row">
          <div className="col-md-6 text-center mb-2">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          <div className="col-md-12" />
          <div className="col-md-6">
            <p>Loading...</p>
          </div>
        </div>
      </div>
    );
  } else if (!event) {
    return <NotFoundPage />;
  }

  if (!event.hotels_retrieved) {
    return (
      <div className='row'>
        <div className='col-md-12 event-list-header'>
          <nav className="navbar navbar-light bg-light">
            <div className="container-fluid">
              <div className="navbar-brand">Event: {event.name} &mdash; <EventDate start={event.event_start} end={event.event_end} /></div>
            </div>
          </nav>
        </div>
        <div className='col-md-7'>
          <Callout type='warning' title='Event Setup in Progress...'>
            <p>We're currently finalizing the setup for this event and retrieving a list of hotels near the main venue.</p>
            <p>Please refresh and try again in a few minutes.</p>
          </Callout>
        </div>
      </div>
    );
  }

  return (
    <div className='row'>
      <div className='col-md-12 event-list-header'>
        <nav className="navbar navbar-light bg-light">
          <div className="container-fluid">
            <div className="navbar-brand">Event: {event.name} &mdash; <EventDate start={event.event_start} end={event.event_end} /></div>
            <form className="d-flex">
              <a className="btn btn-outline-success">
                <GoogleIcon icon='note_add' /> Create Post
              </a>
            </form>
          </div>
        </nav>
      </div>
      <div className='col-md-8'>
        <div className='card event-post event-post-announcement'>
          <div className='card-body'>
            <h5 className='card-title'>
              <GoogleIcon icon='push_pin' className='text-success' altText='Announcement Post' /> <Link to='/posts/123'>BLFC 2021 has been rescheduled to Oct 21 - 24, 2021</Link>
            </h5>
            <div>
              <div className="badge bg-success me-1">Site Announcement</div>
            </div>
            <div className='mt-2 text-muted'>
              <UserAvatar user={{ role: Role.Admin, photo_url: 'https://t.me/i/userpic/320/qe5zJWly8eev88wx8eR-CMI53w7y5YsCTOk4ds-hmtY.jpg' }} />&nbsp;Crashdoom
              <small> posted about 5 minutes ago</small>
            </div>
          </div>
        </div>
        <div className='col-md-8'>
          {posts?.map(post => (
            <EventPost key={post.id} event={event} post={post} />
          ))}
          {(posts && posts.length === 0) && (
            <Callout type='default' title='No Posts Found'>
              Hm, we couldn't find any posts for this event yet. Why not be the first to make one?
            </Callout>
          )}
        </div>
      </div>
    </div>
  )
};
