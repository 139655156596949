import { Role, User } from "../models/User";
import { GoogleIcon } from "./GoogleIcon";

interface RoleIconDefinition {
  icon: string;
  class: string;
  altText: string;
}

const roleMap = new Map<Role, RoleIconDefinition>([
  [Role.Admin, {
    icon: 'shield',
    class: 'user-icon-admin',
    altText: 'ConRoom Exchange Admin',
  }],

  [Role.Moderator, {
    icon: 'shield',
    class: 'user-icon-mod',
    altText: 'ConRoom Exchange Moderator'
  }],

  [Role.PowerUser, {
    icon: 'grade',
    class: 'user-icon-poweruser',
    altText: 'Power User'
  }]
]);

const AvatarIcon: React.FunctionComponent<{ role: Role }> = ({ role }) => {
  const iconDef = roleMap.get(role);
  if (!iconDef) {
    return null;
  }

  return (
    <div className={iconDef.class} title={iconDef.altText}>
      <GoogleIcon icon={iconDef.icon} className='user-icon-bg' />
      <GoogleIcon icon={iconDef.icon} className='user-icon' />
    </div>
  )
}

type Props = {
  user: Pick<User, 'photo_url' | 'role'>;
}

export const UserAvatar: React.FunctionComponent<Props> = ({ user }) => (
  <div className='user-avatar'>
    <img src={user.photo_url} className="rounded-circle" />
    <AvatarIcon role={user.role} />
  </div>
);
