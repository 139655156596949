interface Props {
  icon: string;
  altText?: string;
  className?: string;
  outline?: boolean;
}

export const GoogleIcon: React.FunctionComponent<Props> = ({ altText, className, icon, outline }) => {
  return (
    <span className={`material-icons${outline ? '-outline' : ''} ${className || ''}`} title={altText}>
      {icon}
    </span>
  );
}