import { User } from "./User";

export enum PostType {
  Offer = 'offer',
  Request = 'request',
  Announcement = 'announcement',
}

export enum PostDeleteReason {
  Duplicate = 'duplicate',
  ViolatesToS = 'violates_tos',
  Spam = 'spam',
  Other = 'other',
}

export interface Post {
  id?: string;
  author: User;
  created_on: string;
  event: Event;
  title: string;
  hotel: any;
  check_in: string;
  check_out: string;
  spaces: number;
  amount_per_night: number;
  type: PostType;
  deleted_at?: string;
  deleted_by?: number | null;
  deleted_for?: PostDeleteReason | null;
}
