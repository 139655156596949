interface Props {
  start: string | Date;
  end: string | Date;
  nights?: boolean;
}

export const EventDate: React.FunctionComponent<Props> = ({ start, end, nights }) => {
  const startDate = start instanceof Date ? start : new Date(start);
  const endDate = end instanceof Date ? end : new Date(end);

  const isSameMonth = startDate.getUTCMonth() === endDate.getUTCMonth();

  if (isSameMonth) {
    return (
      <>
        {startDate.toLocaleString('default', { month: 'long' })} {startDate.getUTCDate()} &#8211; {endDate.getUTCDate()}, {startDate.getUTCFullYear()}
        {nights && <> ({getNightsStr(startDate, endDate)})</>}
      </>
    );
  }

  return (
    <>
      {startDate.toLocaleString('default', { month: 'long' })} {startDate.getUTCDate()} &#8211; {endDate.toLocaleString('default', { month: 'long' })} {endDate.getUTCDate()}, {startDate.getUTCFullYear()}
      {nights && <> ({getNightsStr(startDate, endDate)})</>}
    </>
  )
}

export const getNightsStr = (start: Date, end: Date): string => {
  const nightDiff = Math.ceil(Math.abs(end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
  return `${nightDiff} night${nightDiff === 1 ? '' : 's'}`;
}
