import { Link } from "react-router-dom";
import { Event } from "../models/Event";
import { Post, PostType } from "../models/Post";
import { EventDate } from "./EventDate";
import { GoogleIcon } from "./GoogleIcon";
import { TimeAgo } from "./TimeAgo";
import { UserAvatar } from "./UserAvatar";

interface Props {
  post: Post;
  event: Event;
}

export const EventPost: React.FunctionComponent<Props> = ({ event, post }) => {
  const isAnnouncement = post.type === PostType.Announcement;
  const isRequest = post.type === PostType.Request;

  const event_start = new Date(event.event_start);
  const event_end = new Date(event.event_end);

  const check_in = new Date(post.check_in);
  const check_out = new Date(post.check_out);

  return (
    <div className={`card event-post ${isAnnouncement ? 'event-post-announcement' : ''}`}>
      <div className='card-body'>
        <h5 className='card-title'>
          {isAnnouncement && (
            <GoogleIcon icon='push_pin' className='text-success me-2' altText='Announcement Post' />
          )}
          <Link to={`/posts/${post.id}`}>{post.title}</Link>
        </h5>
        <div>
          {isAnnouncement ? (
            <div className="badge bg-success me-1">Site Announcement</div>
          ) : (
            <>
              <div className={`badge me-1 ${isRequest ? 'event-badge-request' : 'event-badge-offer'}`}>
                {isRequest ? 'Request' : 'Offer'}
              </div>
              {check_in < event_start && (
                <div className='text-primary'>
                  <GoogleIcon icon='flight_land' /> Early Arrival
                </div>
              )}
            </>
          )}
        </div>
        <div className='mt-2'>
          {isAnnouncement ? (
            <>blurb about post goes here.</>
          ) : (
            <>
              <div>{post.spaces} Space{post.spaces === 1 ? '' : 's'}</div>
              <div><EventDate start={check_in} end={check_out} nights /></div>
              <div className='mb-1'>
                <a
                  href={`https://google.com/maps/search/?api=1&query=${encodeURIComponent(post.hotel.name)}&query_place_id=${post.hotel.place_id}`}
                  target='_blank'
                >
                  {post.hotel.name}
                </a>
              </div>
            </>
          )}
        </div>
        <div className='mt-2 text-muted'>
          <UserAvatar user={post.author} /> {post.author.first_name} {post.author.last_name}
          <small> posted <TimeAgo date={new Date(post.created_on)} /></small>
        </div>
      </div>
    </div>
  );
};
