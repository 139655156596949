import { User } from "../models/User";
import { ActionType } from "./ActionTypes.enum";

export interface GlobalState {
  user?: User;
}

export const initialState: GlobalState = {};

export interface RootAction {
  type: ActionType;
}

export interface UserAction extends RootAction {
  type: ActionType.LoginSuccess;
  user: User;
}

export const rootReducer = (state: GlobalState = initialState, action: RootAction) => {
  switch (action.type) {
    case ActionType.LoginSuccess:
      const { user } = action as UserAction;
      return { ...state, user };

    default:
      return state;
  }
};
