interface Props {
  type: 'default' | 'primary' | 'success' | 'warning' | 'danger';
  title: string;
}

export const Callout: React.FunctionComponent<Props> = ({ children, type, title }) => {
  return (
    <div className='row'>
      <div className='col-md-8'>
        <div className={`callout callout-${type}`}>
          <h5>{title}</h5>
          {children}
        </div>
      </div>
    </div>
  );
}
