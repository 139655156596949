import '../../node_modules/bootstrap-icons/font/bootstrap-icons.css';

interface Props {
  icon: string;
  className?: string;
}

export const BootstrapIcon: React.FunctionComponent<Props> = ({ className, icon }) => {
  return (
    <span className={`bootstrap-icon bi bi-${icon} ${className || ''}`} />
  );
}