interface Props {
  isAuthError?: boolean;
}

export const AccessDenied: React.FunctionComponent<Props> = ({ isAuthError }) => {
  if (isAuthError) {
    return (
      <div className='row'>
        <div className='col-md-8'>
          <div className='callout callout-danger'>
            <h5>Access Denied</h5>
            <p>You do not have permission to access this resource.</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='row'>
      <div className='col-md-8'>
        <div className='callout callout-danger'>
          <h5>Login Required</h5>
          <p>You need to be logged in to access this resource.</p>
        </div>
      </div>
    </div>
  );
};
