import { Route, Switch } from "react-router-dom";
import { AboutPage } from "./About";
import { AddEventPage } from "./AddEvent";
import { MaintenancePage } from "./errors/Maintenance";
import { NotFoundPage } from "./errors/NotFound";
import { EventListPage } from "./EventList";
import { EventPostList } from "./EventPostList";

export const Router: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <MaintenancePage />
      </Route>
      <Route exact path='/events'>
        <EventListPage />
      </Route>
      <Route exact path='/events/add'>
        <AddEventPage />
      </Route>
      <Route exact path='/events/:id' component={EventPostList} />
      <Route path='/about'>
        <AboutPage />
      </Route>
      <Route path='*'>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};
