import axios, { AxiosRequestConfig } from 'axios';
import base64js from 'base64-js';
import { TelegramUser } from './components/HeaderUserInfo';
import { Event } from './models/Event';
import { Post } from './models/Post';
import { LoginToken, User } from './models/User';

export class APIClient {
  public async login(tgUser: TelegramUser): Promise<LoginToken> {
    return this.request<LoginToken>({
      method: 'post',
      url: '/users/login',
      data: {
        id: tgUser.id,
        json: this.base64Encode(tgUser),
      },
    });
  }

  public async getUserInfo(token: string): Promise<User> {
    return this.request<User>({
      method: 'get',
      url: '/users/current',
      headers: {
        'authorization': 'Bearer ' + token,
      },
    });
  }

  public async getEvents(): Promise<Event[]> {
    return this.request<Event[]>({ method: 'get', url: '/events' });
  }

  public async getEventById(id: string): Promise<Event> {
    return this.request<Event>({ method: 'get', url: `/events/${id}` });
  }

  public async getEventPosts(id: string): Promise<Post[]> {
    return this.request<Post[]>({ method: 'get', url: `/events/${id}/posts` });
  }

  public async request<T>(opts: AxiosRequestConfig): Promise<T> {
    opts.url = 'http://localapi.conroom.exchange:3000' + opts.url;
    const { data } = await axios.request<T>(opts);
    return data;
  }

  private base64Encode(json: unknown): string {
    const bytes = new TextEncoder().encode(JSON.stringify(json));
    return base64js.fromByteArray(bytes);
  }
}
