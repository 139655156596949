import { convertDateToUTC } from "../utils";
import { GoogleIcon } from "./GoogleIcon";

interface Props {
  date: Date;
  icon?: boolean;
}

let timeAgoId = 1;

export const TimeAgo: React.FunctionComponent<Props> = ({ date, icon }) => {
  const timeDiff = (convertDateToUTC() - convertDateToUTC(date)) / 1000;
  const key = `timeAgo-${timeAgoId++}`;

  return (
    <>
      <span className='time-ago' id={key}>
        {icon && <GoogleIcon icon='access_time' />}
        {getTimeAgoText(timeDiff)}
      </span>
    </>
  );
};

const getTimeAgoText = (rawSeconds: number): string => {
  const seconds = Math.floor(rawSeconds);
  if (seconds < 30) {
    return 'just now';
  }

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  }

  const minutes = Math.floor(seconds / 60);
  if (minutes === 1) {
    return 'a minute ago';
  }

  if (minutes < 60) {
    return `${minutes} minutes ago`;
  }

  const hours = Math.floor(minutes / 60);
  if (hours === 1) {
    return 'an hour ago';
  }

  if (hours < 24) {
    return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  }

  const days = Math.floor(hours / 24);
  if (days === 1) {
    return 'yesterday';
  }

  if (days < 7) {
    return `${days} days ago`;
  }

  const weeks = Math.floor(days / 7);
  if (weeks === 1) {
    return 'a week ago';
  }

  if (weeks < 4) {
    return `${weeks} weeks ago`;
  }

  // TODO: Find a better way to do this. MONTHS ARE HARD. AAAAAAAAAA.
  const months = Math.floor(weeks / 4);
  if (months === 1) {
    return 'a month ago';
  }

  if (months < 12) {
    return `${months} months ago`;
  }

  const years = Math.floor(months / 12);
  if (years === 1) {
    return 'a year ago';
  }

  return `${years} years ago`;
};