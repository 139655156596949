import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EventListItem } from "../components/EventListItem";
import { Event } from "../models/Event";

export const EventListPage: React.FunctionComponent = () => {
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    api.getEvents().then(e => setEvents(e)).catch(console.error)
  }, []);

  return (
    <div className='row'>
      <div className='col-md-12 event-list-header'>
        <nav className="navbar navbar-light bg-light">
          <div className="container-fluid">
            <div className="navbar-brand">{events.length} Upcoming Event(s)</div>
            <Link to='/events/add' className='btn btn-success'>Add New Event</Link>
            <form className="d-flex">
              <input className="form-control me-2" type="search" placeholder="Search for Event" aria-label="Search" />
              <button className="btn btn-outline-primary" type="submit">Search</button>
            </form>
          </div>
        </nav>
      </div>
      {events.map(event => <EventListItem key={event.id} event={event} />)}
    </div>
  );
};
