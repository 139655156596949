import { TelegramUser } from "../components/HeaderUserInfo";

export interface User extends TelegramUser {
  role: Role;
}

export interface LoginToken {
  access_token: string;
}

export enum Role {
  Banned = 'banned',
  User = 'user',
  PowerUser = 'poweruser',
  Moderator = 'moderator',
  Admin = 'administrator',
}
