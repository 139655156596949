import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ConRoomExchangeApp.scss';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './pages/Router';

export const App: React.FunctionComponent = () => {
  // Self-XSS Warning
  console.log("%cWAIT! STOP!%c\n\nThis is a tool intended for %cDEVLOPER%c use only!\n\nIf someone told you to paste anything here, you're most likely being %cSCAMMED%c with an attack called Self-XSS.\n\nFor more information, visit: https://en.wikipedia.org/wiki/Self-XSS", "color: red; font-size:32px;", "font-size: 20px;", "font-size: 20px; color: #00BB92;", "font-size: 20px;", "font-size: 20px; color: red;", "font-size: 20px");

  return (
    <div className="d-flex flex-column min-vh-100">
      <BrowserRouter>
        <Header />
        <main className='flex-fill'>
          <div className="container">
            <Router />
          </div>
        </main>
        {/* <BanModal /> */}
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
