import { useSelector } from "react-redux";
import { AccessDenied } from "../components/AccessDenied";
import { Role } from "../models/User";
import { GlobalState } from "../services/RootReducer";

export const AddEventPage: React.FunctionComponent = () => {
  const { user } = useSelector((state: GlobalState) => state);
  const header = (
    <div className='col-md-12 event-list-header'>
      <nav className="navbar navbar-light bg-light">
        <div className="container-fluid">
          <div className="navbar-brand">Add New Event</div>
        </div>
      </nav>
    </div>
  );

  if (!user || user.role === Role.Banned) {
    return (
      <div className='row'>
        {header}
        <AccessDenied isAuthError={user?.role === Role.Banned} />
      </div>
    );
  }

  const required = <span className="badge bg-danger">Required</span>;
  const now = new Date();
  const today = now.getUTCFullYear() + '-' + (now.getUTCMonth() + 1).toString().padStart(2, '0') + '-' + now.getUTCDate().toString().padStart(2, '0');

  return (
    <div className='row'>
      {header}
      <div className='col-md-6'>
        {user.role === Role.User ? (
          <div className='callout callout-warning add-event-review-required'>
            <h5>Review Required</h5>
            <p>
              To ensure quality, correctness, and to prevent vandalism, all changes by regular users are subject to Moderator review before being made visible on the site.
            </p>
            <p>
              Users with the <code>Power User</code> role are trusted community editors and are able to bypass this restriction.
            </p>
          </div>
        ) : (
          <div className='callout callout-success add-event-review-not-required'>
            <h5>Review Not Required</h5>
            <p>
              As a trusted user, your additions and edits are not subject to review. However, abuse of this privilege will result in it being revoked or your account being permanently suspended.
            </p>
          </div>
        )}
        <div className='callout'>
          <h5>Guidelines</h5>
          <p>
            When creating or updating an event, please ensure you use the latest up-to-date information and provide a link to the primary source for the information used.
          </p>
          <p>
            Examples of Valid Sources:
            <ul>
              <li><strong>Official Website</strong> &mdash; This is the <strong>best</strong> source of information for an event.</li>
              <li><strong>Official Social Media</strong> &mdash; If there isn't an official website, or the website hasn't been updated, there may be announcements that would be sufficient.</li>
              <li><strong>Reputatble News Article</strong> &mdash; For larger events, local news may offer information on confirmation of event dates or postponement.</li>
            </ul>
          </p>
          <p>
            Examples of Non-Valid Sources:
            <ul>
              <li><strong>Third-Party / Hearsay</strong> &mdash; Unofficial comments or remarks by a third-party are <strong>NOT</strong> valid.</li>
              <li><strong>Wikis</strong> &mdash; As a wiki can be edited by anyone, it isn't itself a valid source. However, the reference sources on the wiki may be valid.</li>
            </ul>
          </p>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='card mb-3'>
          <div className='card-body'>
            <h4>Core Information</h4>
            <div className="mb-3">
              <label className="form-label">Event Name {required}</label>
              <input type="text" name="name" className="form-control" id="name" placeholder="Example: A N FurCon" required />
            </div>
            <div className="mb-3">
              <label className="form-label">Event Logo</label>
              <input type="text" name="logo_link" className="form-control" id="logo_link" placeholder="Example: https://best.ever/event/logo.png" />
            </div>
            <div className="mb-3">
              <label className="form-label">Venue Name {required}</label>
              <input type="text" name="venue_name" className="form-control" id="venue_name" placeholder="Example: Marriote, Sheepton Hotel &amp; Resorts, Single Season Hotel &amp; Resorts" required />
            </div>
            <div className="mb-3">
              <label className="form-label">Venue Address {required}</label>
              <input type="text" name="venue_address" className="form-control" id="venue_address" placeholder="Example: 123 Furry Ln, Zootopia, DSNY" />
            </div>
            <div className="mb-3">
              <label className="form-label">Event Start Date {required}</label>
              <input type="date" name="event_start" className="form-control" id="event_start" min={today} />
            </div>
            <div className="mb-3">
              <label className="form-label">Event End Date {required}</label>
              <input type="date" name="event_end" className="form-control" id="event_end" min={today} />
            </div>
          </div>
        </div>
        <div className='card mb-3'>
          <div className='card-body'>
            <h4>Social Media Information</h4>
            <div className="mb-3">
              <label className="form-label">Official Event Website</label>
              <input type="text" name="website_link" className="form-control" id="website_link" placeholder="Example: https://best.event/ever" />
            </div>
            <div className="mb-3">
              <label className="form-label">Official Twitter</label>
              <input type="text" name="twitter_link" className="form-control" id="twitter_link" placeholder="Example: https://twitter.com/besteverevent" />
            </div>
            <div className="mb-3">
              <label className="form-label">Event Wiki Page</label>
              <input type="text" name="wiki_link" className="form-control" id="wiki_link" placeholder="Example: https://en.wikifur.com/wiki/BestEverEvent_(2021)" />
            </div>
          </div>
        </div>
        <div className='card mb-3'>
          <div className='card-body'>
            <div className="form-check form-check-inline mb-3">
              <input className="form-check-input" type="checkbox" id="guidelines" name="guidelines" value="guidelines" />
              <label className="form-check-label" htmlFor="guidelines">I have read, understood, and have ensured the information above meet the provided guidelines.</label>
            </div>

            <hr />

            <div className="d-grid gap-2 col-6 mx-auto">
              <button type='submit' className='btn btn-primary'>Create Event</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
