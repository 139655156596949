import { Link } from "react-router-dom";
import NotFoundSVG from './404-error.svg';

export const NotFoundPage: React.FunctionComponent = () => {
  return (
    <div className='text-center mt-4 empty-state-message'>
      <img src={NotFoundSVG} alt='404 Not Found!' />
      <h5>Whoops, nothing here but this error!</h5>
      <p className='text-muted'>It seems you may have got lost and need help getting back on the right track. </p>
      <Link to='/' className='btn btn-outline-primary'>Back to the Homepage</Link>
    </div>
  )
};
