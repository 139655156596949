import { Event } from '../models/Event';
import { BootstrapIcon } from './BootstrapIcon';
import { EventDate } from './EventDate';
import { GoogleIcon } from './GoogleIcon';

interface Props {
  event: Event;
}

export const EventListItem: React.FunctionComponent<Props> = ({ event }) => {
  const url = new URL('https://google.com/maps/search/?api=1');
  url.searchParams.append('query', `${event.venue_name} ${event.venue_address}`);

  return (
    <div className='mb-3 event-item col-md-4'>
      <div className='card'>
        <div className='row g-0'>
          <div className='col-md-3 event-logo'>
            <img src={event.logo_link} alt={`${event.name} Logo`} />
          </div>
          <div className='col-md-9 event-body'>
            <div className='card-body'>
              <h5 className='card-title'>
                {event.name}
              </h5>
              <div className='card-text'>
                <EventDate start={event.event_start} end={event.event_end} />
              </div>
              <div className='event-socials text-muted'>
                {event.website_link && (
                  <div className='event-social event-social-website'>
                    <a href={event.website_link} target='_blank'>
                      <BootstrapIcon icon={'globe'} />
                    </a>
                  </div>
                )}
                {event.twitter_link && (
                  <div className='event-social event-social-twitter'>
                    <a href={event.twitter_link} target='_blank'>
                      <BootstrapIcon icon={'twitter'} />
                    </a>
                  </div>
                )}
                {event.wiki_link && (
                  <div className='event-social event-social-wiki'>
                    <a href={event.wiki_link} target='_blank'>
                      <BootstrapIcon icon={'book'} />
                    </a>
                  </div>
                )}
              </div>
              <hr />
              <div className='card-text'>
                {event.venue_name}
                <a
                  className='material-icon-link'
                  href={url.href}
                  target='_blank'
                >
                  <GoogleIcon icon='location_on' />
                </a>
              </div>
              <div className='card-text text-muted'>
                {event.venue_address}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
